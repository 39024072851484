import React from "react";

const TextareaField = ({ label, id, value, onChange }) => {
  return (
    <div className="contact__input--container">
      <label htmlFor={id} className="contact__label">
        {label}
      </label>
      <textarea name={id} id={id} value={value} onChange={onChange}></textarea>
    </div>
  );
};

export default TextareaField;
