import "./App.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

/* Components */
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import ScrollToTop from "./lib/ScrollToTop";
import getEmailOptions from "./lib/getEmailOptions"

/* Pages */
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Privacy from "./pages/Privacy";
import Cookies from "./pages/Cookies";
import NotFound from "./pages/NotFound";

/* AOS */
import AOS from "aos";
import "aos/dist/aos.css";

import emailjs from "@emailjs/browser"
import { useEffect } from "react";
import Pricing from "./pages/Pricing";
import Services from "./pages/Services";

function App() {
  AOS.init({
    duration: 800,
    delay: 500,
    once: true
  });

  useEffect(() => {
    const emailJSInit = async () => {
      const options = await getEmailOptions()

      emailjs.init(options)
    }

    emailJSInit()
  }, [])


  return (
    <Router>
      <ScrollToTop />
      <Nav></Nav>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/contact/:product" element={<Contact />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/cookie-policy" element={<Cookies />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/services" element={<Services />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
      <Footer></Footer>
    </Router>
  );
}

export default App;
