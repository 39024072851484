import { useEffect, useRef, useState } from "react";

function SkillBar({ index, title, id }) {
  const [isVisible, setIsVisible] = useState(false);
  const targetRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setTimeout(() => {
            setIsVisible(true);
          }, (index - 1) * 200);
        }
      },
      {
        threshold: 0,
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    // Clean up the observer
    return () => {
      observer.disconnect();
    };
  }, [index]);

  return (
    <div ref={targetRef} className={`skill__bar--wrapper`}>
      <h1 className="skill__bar--title">{title}</h1>
      <div className={`skill__bar ${isVisible && id + "-bar"}`}></div>
    </div>
  );
}

export default SkillBar;
