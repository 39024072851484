import React from "react";
import { FaArrowRight } from "react-icons/fa6";

function ArrowBTN({ text, onClick }) {
  return (
    <button onClick={onClick} className="btn btn--arrow click">
      <p className="btn--text">{text}</p>
      <FaArrowRight></FaArrowRight>
    </button>
  );
}

export default ArrowBTN;
