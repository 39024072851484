import React from "react";
import Testimonial from "../UI/Home/Testimonial";
import vertigoesPFP from "../../assets/PFPs/VertigoesPFP.jpg"

function Testimonials() {
  return (
    <section id="testimonials">
      <div className="container">
        <div className="row">
          <h1 className="testimonials__title title">What Our <span className="primcolor">Clients</span> Say</h1>
          <div className="section-line"></div>
          <div className="testimonials--container">
            <Testimonial
              text={
                "AG Web Studios has captured our visual musical expression. AG Web has managed to integrate music videos and images in the best possible way. We have been very satisfied with the collaboration."
              }
              name="The Vertigoes"
              job="Band"
              pfp={vertigoesPFP}
            ></Testimonial>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
