import React from "react";
import "../style/Packages/Packages.scss";
import "../style/Packages/Breakpoints.scss";
import PackagesSection from "../components/Packages/PackagesSection";
import GuidanceSection from "../components/Packages/GuidanceSection";
import useDocumentTitle from "../lib/useDocumentTitle";
import FAQ from "../components/Packages/FAQ";

function Pricing() {
  useDocumentTitle("Pricing")
  return (
    <main id="packages-page">
      <PackagesSection></PackagesSection>
      <GuidanceSection></GuidanceSection>
      <FAQ></FAQ>
    </main>
  );
}

export default Pricing;
