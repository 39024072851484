import "../style/About/AboutPage.scss";
import "../style/About/Breakpoints.scss";
import useDocumentTitle from "../lib/useDocumentTitle";

function About() {
  useDocumentTitle("About");

  return (
    <main id="about-page">
      <header id="about-landing">
        <div className="container">
          <div className="row">
            <div data-aos="fade-right" className="white-line"></div>
            <h1 data-aos="fade-left" className="about--title title">
              Crafting Exceptional
              <br />
              Digital Experiences
            </h1>
            <div data-aos="fade-right" className="about__header-bottom">
              <p className="about__header-para">
                At AG Web Studios, we transform your ideas into compelling
                digital experiences that drive business success.
              </p>
              <div className="white-line"></div>
              <p className="about__header-para">
                Innovative Solutions, Proven Results - Our dedicated team of
                designers and developers work collaboratively to deliver
                innovative solutions that meet your business goals.
              </p>
            </div>
          </div>
        </div>
      </header>
    </main>
  );
}

export default About;
