import React from "react";

const Skeleton = ({ width, height, borderRadius, classN }) => {
  return (
    <div
      className={`skeleton-box ${classN}`}
      style={{
        width,
        height,
        borderRadius,
      }}
    ></div>
  );
};

export default Skeleton;