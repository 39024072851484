import React from "react";
import { FaArrowRight } from "react-icons/fa6";
import { Link } from "react-router-dom";

function ArrowLink({ destination, text }) {
  return (
    <Link to={destination} className="btn primcolor-bg btn--arrow click">
      <p className="btn--text">{text}</p>
      <FaArrowRight></FaArrowRight>
    </Link>
  );
}

export default ArrowLink;
