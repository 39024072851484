import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.scss";
import example1 from "../../assets/designs/www.akselglyholt.com.jpg";
import example2 from "../../assets/designs/library.akselglyholt.com_.jpg";
import example3 from "../../assets/designs/internship-snowy.vercel.app_.jpg";
import example4 from "../../assets/designs/learnluau.png";
import Design from "../UI/Home/Design";

function Designs() {
  const designs = [
    { img: example1, title: "Example 1" },
    { img: example2, title: "Example 2" },
    { img: example3, title: "Example 3" },
    { img: example4, title: "Example 4" },
  ];
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getSlidesToShow = () => {
    if (windowWidth >= 1150) {
      return 3;
    } else if (windowWidth >= 800) {
      return 2;
    } else {
      return 1;
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: getSlidesToShow(),
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <section id="designs">
      <div className="container">
        <div className="row">
          <h1 className="designs--title title">
            Here is some of our <span className="primcolor">work</span>
          </h1>
          <div className="section-line"></div>
          <p className="designs--text sub-title">
            These are some examples for some websites we're capable of creating
          </p>
          <div className="designs--container">
            <Slider {...settings}>
              {designs.map((design, index) => (
                <div key={index}>
                  <Design img={design.img} title={design.title}></Design>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Designs;
