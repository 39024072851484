import React from "react";

function Testimonial({ text, name, job, pfp }) {
  return (
    <div className="testimonial">
      <div className="testimonial__pfp--container">
        <img src={pfp} alt="" className="testimonial__pfp" />
      </div>
      <div className="testimonial--right">
        <p className="testimonial__para para">"{text}"</p>
        <h2 className="testimonial__name">{name}</h2>
        <p className="testimonial__job">{job}</p>
      </div>
    </div>
  );
}

export default Testimonial;
