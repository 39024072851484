import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/AGWebLogo-Full.webp";
import { FaXTwitter } from "react-icons/fa6";

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="footer--section">
            <h1 className="footer--title">Website</h1>
            <ul className="footer--links">
              <li className="footer--link">
                <Link to={"/home"}>Home</Link>
              </li>
              <li className="footer--link">
                <Link to={"/about"}>About us</Link>
              </li>
              <li className="footer--link">
                <Link to={"/contact"}>Contact</Link>
              </li>
              <li className="footer--link">
                <Link to={"/pricing"}>Pricing</Link>
              </li>
            </ul>
          </div>
          <div className="footer--section">
            <h1 className="footer--title">Company</h1>
            <ul className="footer--links">
              <li className="footer--link">
                <Link to={"/services"}>Services</Link>
              </li>
              <li className="footer--link">
                <Link to={"/about"}>About us</Link>
              </li>
              <li className="footer--link">
                <Link to={"/contact"}>Contact</Link>
              </li>
            </ul>
          </div>
          <div className="footer--section">
            <h1 className="footer--title">Legal</h1>
            <ul className="footer--links">
              <li className="footer--link">
                <Link to={"/privacy-policy"}>Privacy Policy</Link>
              </li>
              <li className="footer--link">
                <Link to={"/cookie-policy"}>Cookie Policy</Link>
              </li>
            </ul>
          </div>
          <div className="footer--section">
            <figure className="footer__img--wrapper">
              <img src={logo} alt="" className="footer__img" />
            </figure>
            <p className="footer--para">
              +45 23 81 93 39<span className="splitter"></span>
              contact@agweb.studio
            </p>
            <a href="https://www.x.com/agwebstudios" target="_blank" rel="noreferrer noopener" className="footer__social">
              <FaXTwitter />
            </a>
            <div className="footer__copyright--container">
              <Link
                to={"/privacy-policy"}
                className="footer__privacy--policy--link"
              >
                Privacy Policy
              </Link>
              <p className="footer__copyright--para">
                &#169; 2024 AG Web Studios. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
