import axios from "axios";

const getEmailOptions = async () => {
  const emailBlacklist = await axios.get("https://files.agweb.studio/?file=emailBlacklist.json")

  const options = {
    publicKey: "Gil_NuAHZ8sgAhBVN",
    // Do not allow headless browsers
    blockHeadless: true,
    blockList: {
      // Block the suspended emails
      list: emailBlacklist.data,
      // The variable contains the email address
      watchVariable: "userEmail",
    },
    limitRate: {
      // Set the limit rate for the application
      id: "app",
      // Allow 1 request per 10s
      throttle: 10000,
    },
  };

  return options
};

export default getEmailOptions