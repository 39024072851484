import Service from "./UI/Service";

const Services = () => {
  return (
    <section id="services">
      <div className="container">
        <div className="row col">
          <h1 className="title">Our Services</h1>
          <div className="section-line"></div>
          <div className="services--container">
            <Service
              title="Web Design"
              text="Custom designs tailored to your brand and audience."
              icon="🎨"
            />
            <Service
              title="Web Development"
              text="Robust and scalable websites built with the latest technologies."
              icon="💻"
              delay="300"
            />
            <Service
              title="SEO Optimization"
              text="Improve your search rankings and online visibility."
              icon="📈"
              delay="500"
            />
            <Service
              title="Backend Development"
              text="Stable and fast servers built specifically to your needs."
              icon="💾"
              delay="100"
            />
            <Service
              title="Mobile Apps"
              text="Custom iOS and Android apps to reach your audience on any device."
              icon="📱"
              delay="300"
            />
            <Service
              title="Maintenance & Support"
              text="Ongoing maintenance to ensure uptime and security."
              icon="🛠️"
              delay="500"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
