const LandingImg = ({ first, Icon, text, title, img }) => {
  function onHover(event) {
    //Check if hovered element already has class
    if (!event.currentTarget.classList.contains("landing__img--open")) {
      // Element does not currently have open class
      const currentOpenElem = document.querySelector(".landing__img--open"); // Find current open element

      //Remove class from current open elem
      if (currentOpenElem) {
        currentOpenElem.classList.remove("landing__img--open");
      }

      //Apply open class to hovered elem
      event.currentTarget.classList.add("landing__img--open");
    }
  }

  return (
    <div
      className={`landing__img--wrapper ${first && "landing__img--open"}`}
      onMouseEnter={onHover}
    >
      <img src={img} alt="" className="landing__img" />
      <div className="landing__img--info-box">
        {Icon && <Icon className="landing__img--info-icon" />}
        <div className="landing__img--info-container">
          <h1 className="landing__img--info-title">{title}</h1>
          <span className="landing__img--info-text">{text}</span>
        </div>
      </div>
    </div>
  );
};

export default LandingImg;
