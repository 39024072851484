import React, { useState, useEffect } from "react";
import Skeleton from "../Skeleton";

function Design({ img, title }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = img;

    const handleImageLoad = () => {
      setImageLoaded(true);
    };

    image.onload = handleImageLoad;

    return () => {
      image.onload = null;
    };
  }, [img]);

  return (
    <div className="design--wrapper">
      <div className="design">
        <h2 className="design--title">{title}</h2>
        <div className="design__img--wrapper">
          {imageLoaded ? (
            <img
              className="design__img"
              src={img}
              alt="Design example"
              loading="auto"
            ></img>
          ) : (
            <Skeleton classN="design__img" />
          )}
        </div>
      </div>
    </div>
  );
}

export default Design;
