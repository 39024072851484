const Service = ({ title, text, icon, delay }) => {
  return (
    <div data-aos="fade-right" data-aos-delay={delay || "100"} className="service col">
      <span className="service--icon primcolor">{icon}</span>
      <h1 className="service--title">{title}</h1>
      <para className="service--para">{text}</para>
    </div>
  );
};

export default Service;
