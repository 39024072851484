import React from "react";

const InputField = ({ label, type, id, autoComplete, value, onChange, required }) => {
  return (
    <div className="contact__input--container">
      <label htmlFor={id} className="contact__label">
        {label}
      </label>
      <input
        type={type}
        autoComplete={autoComplete}
        className="contact--input"
        id={id}
        value={value}
        onChange={onChange}
        name={id}
        required={required}
      />
    </div>
  );
};

export default InputField;
