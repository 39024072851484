import { IoIosColorPalette } from "react-icons/io";
import ExpertPoint from "./UI/ExpertPoint";
import { FaMobileScreenButton, FaServer } from "react-icons/fa6";
import { GoGraph } from "react-icons/go";

const Expertise = () => {
  return (
    <section id="expertise">
      <div className="container">
        <div className="flex-row">
          <div className="left">
            <h1 className="title">Our Expterise</h1>
            <div className="section-line"></div>
            <p className="para sub-title">
              At AG Web Studios, we specialize in creating stunning websites
              that drive results. Our team is dedicated to providing top-notch
              web solutions tailored to your business needs.
            </p>
            <div className="expert__points--container">
              <ExpertPoint
                title="Custom Web Design"
                para="Crafting unique and responsive designs to make your business stand out."
                Icon={IoIosColorPalette}
              />
              <ExpertPoint
                title="Backend Development"
                para="Building robust server-side solutions for seamless functionality."
                Icon={FaServer}
              />
              <ExpertPoint
                title="SEO Optimization"
                para="Enhancing your online visibility with effective SEO strategies."
                Icon={GoGraph}
              />
              <ExpertPoint
                title="Mobile App Development"
                para="Creating innovative mobile applications for iOS and Android platforms."
                Icon={FaMobileScreenButton}
              />
            </div>
          </div>
          <div className="right">
            <div className="expert__img--wrapper">
              <div className="expert__img--line"></div>
              <img
                src="https://images.unsplash.com/photo-1633835347263-59097ff7ab29?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wzNjAzNTV8MHwxfHJhbmRvbXx8fHx8fHx8fDE3Mjc0NTg1ODl8&ixlib=rb-4.0.3&q=80&w=2832"
                alt=""
                className="expert__img"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Expertise;
