import React from "react";
import ContactForm from "../components/Contact/ContactForm";
import "../style/Contact/Contact.scss"
import "../style/Contact/Breakpoints.scss"
import useDocumentTitle from "../lib/useDocumentTitle";

function Contact() {
  useDocumentTitle("Contact")
  return (
    <div id="contact">
      <div className="container">
        <div className="row">
          <div className="contact--left left">
            <h1 className="contact--title title">
              Let&#x2019;s build something great together
            </h1>
            <p className="contact--sub-title sub-title">
              We believe in turning ideas into reality and we're ready to join
              your journey. Reach out to us and let's start discussing your
              project.
            </p>
            <p className="contact--info sub-title">
              contact@agweb.studio
              <br />
              +45 23 81 93 39
            </p>
          </div>
          <div className="contact--right right">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
