import React from "react";

function Scroll({ anchor }) {
  return (
    <a aria-label="Scroll Down" href={`#${anchor}`} className="scroll">
      <div className="scroll__icon click"></div>
    </a>
  );
}

export default Scroll;
